import { Button, EButtonSize, FixedElement, TAction } from '@front/shared/ds';
import Image, { ImageProps } from 'next/image';
import Link from 'next/link';
import React from 'react';

export type THeroSectionProps = {
  actions: TAction[];
  fixedAction?: TAction[];
  title: string;
  subtitle?: string;
  icon?: ImageProps;
};

const HeroSection: React.FC<THeroSectionProps> = props => {
  const { actions = [], title, subtitle, fixedAction, icon } = props;

  return (
    <section>
      {fixedAction &&
        fixedAction?.map(action => (
          <FixedElement key={title} action={action} />
        ))}
      <div className='grid items-center rounded-3xl bg-interface-1000 p-4 pt-[9.75rem] lg:h-[31.25rem] lg:p-8'>
        <div className='z-10 mt-auto grid gap-8 lg:mt-0 lg:auto-rows-max lg:gap-12'>
          <div className='mx-auto flex flex-col gap-5 text-center lg:w-7/12 2xl:max-w-[51rem]'>
            {icon && <Image {...icon} alt={icon.alt} />}
            <h1 className='text-6xl font-semibold leading-relaxed text-surface-50 2xl:text-9xl 2xl:leading-tight'>
              {title}
            </h1>
            {subtitle && (
              <h2 className='text-base font-medium text-surface-50 2xl:text-surface-50/70'>
                {subtitle}
              </h2>
            )}
          </div>
          <div className='mx-auto flex w-full max-w-[22rem] flex-col justify-center gap-4 empty:hidden 2xl:max-w-[50rem] 2xl:flex-row'>
            {actions.map((action, index) => (
              <div className='grid 2xl:grid' key={index}>
                {action.type === 'button' && (
                  <Button {...action.props} size={EButtonSize.Large} />
                )}
                {action.type === 'link' && (
                  <Link
                    className='flex items-center justify-center gap-2.5 rounded-2xl bg-brand-500 px-10 py-5 text-center text-base font-medium leading-tight text-brand-50/90 transition last:border last:border-brand-500 last:bg-transparent last:text-brand-500 hover:bg-brand-550 hover:text-brand-50'
                    {...action.props}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
