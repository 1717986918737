import Image, { ImageProps } from 'next/image';
import React from 'react';

import { HeroSection } from '../HeroSection';

export type THeroSectionHomePageProps = React.ComponentProps<
  typeof HeroSection
>;

const HeroSectionHomePage: React.FC<THeroSectionHomePageProps> = props => {
  const icon: ImageProps = {
    src: '/images/home/icon.svg',
    alt: 'fxwhitelabel-io icon logo',
    width: 56,
    height: 56,
    className: 'mx-auto',
  };
  return (
    <div className='relative'>
      <div className='pointer-events-none absolute inset-0'>
        <Image
          key='home-img-mobile'
          src='/images/home/mobile.png'
          alt='Home Mobile'
          width={768}
          height={140}
          className='absolute left-1/2 min-w-[48rem] -translate-x-1/2 lg:hidden'
          priority
        />
        <Image
          key='home-desktop-left-top'
          src='/images/home/desktop_left.png'
          alt='Home desktop left Top'
          width={512}
          height={500}
          className='absolute inset-y-0 left-[calc(50%-32rem)] z-0 hidden lg:block 2xl:left-0'
          fetchPriority='high'
        />
        <Image
          key='home-desktop-right-top'
          src='/images/home/desktop_right.png'
          alt='Home destop right top'
          width={512}
          height={500}
          className='absolute inset-y-0 right-[calc(50%-32rem)] z-0 hidden  lg:block 2xl:right-0 2xl:top-0'
          fetchPriority='high'
        />
      </div>
      <HeroSection {...props} icon={icon} />
    </div>
  );
};

export default HeroSectionHomePage;
